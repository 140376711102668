import http from "./http"
const newUrl = 'https://pay.gxb.cn/api'

//获取任务信息
export const $apiGetTaskInfo = (data) => http({ method: "post", url: newUrl+"/complete/award", data })

//领取任务奖励
export const $apiDrawAward = (data) => http({ method: "post", url: newUrl+"/draw/award", data })


